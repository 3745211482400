<template>
	<div class="bigDiv">
		<!-- 面包屑导航 -->
		<el-breadcrumb separator-class="el-icon-arrow-right">
		  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>代理商管理</el-breadcrumb-item>
		  <el-breadcrumb-item>代理商列表</el-breadcrumb-item>
		</el-breadcrumb>
		<div style="margin-top:20px">
			<el-card>
				<el-row :gutter="10">
					<el-col :span="8">
						<el-input placeholder="按账号号查询" v-model="queryInfo.query" class="input-with-select" clearable @clear="seachVal">
						<el-button slot="append" icon="el-icon-search" @click="ListArr"></el-button>
						</el-input>
					</el-col>
					<el-button type="primary" @click="AddUser">新增</el-button>
				</el-row>
				<el-table
				      :data="ArrList"
				      style="margin-top: 20px;"
					  border>
					  <el-table-column
						label="序号"
					   align="center"
						type="index"
						width="50">
					  </el-table-column>
					  <el-table-column
					    prop="ID"
					    label="用户ID"
					    width="250">
					  </el-table-column>
				      <el-table-column
				        prop="USERID"
				        label="用户账号"
				        width="250">
				      </el-table-column>
				      <el-table-column
				        prop="USERNAME"
				        label="用户姓名"
				        width="250">
				      </el-table-column>
					  <el-table-column
					    prop="CDATE"
					    label="创建时间"
					    width="250">
					  </el-table-column>
					  <el-table-column
					    prop="LOGINDATE"
					    label="最后登陆日期"
					    width="250">
					  </el-table-column>
					  <!-- <el-table-column
						prop="ISCHECKED"
						label="状态"
						align="center"
						width="250">
						<template slot-scope="scope">
						  <el-tag
							:type="scope.row.ISCHECKED === 0 ? 'success' : 'danger'"
							disable-transitions>{{scope.row.ISCHECKED==0?'正常':'禁用'}}</el-tag>
						</template>
					  </el-table-column> -->
					  <el-table-column label="操作" align="center">
						<template slot-scope="scope">
						  <el-button
							size="mini"
							@click="handleEdit(scope.$index, scope.row)">预览</el-button>
						</template>
					  </el-table-column>
				    </el-table>
					<!-- 分页功能 -->
					<div style="margin-top: 20px;">
						<el-pagination
						  @size-change="SizeChange"
						  @current-change="CurrentChange"
						  :current-page="queryInfo.pagenum"
						  :page-sizes="[10, 30, 50]"
						  :page-size="queryInfo.pagesize"
						  layout="total, sizes, prev, pager, next, jumper"
						  :total="total">
						</el-pagination>
					</div>
			</el-card>
		</div>
		<!-- 新增 -->
		<el-dialog title="新增账号" width="100" :visible.sync="dialogFormVisible">
		  <el-form :rules="rules" :model="addListForm" ref="addListForm">
		    <el-form-item label="用户账号" prop="USERID">
		      <el-input v-model="addListForm.USERID"></el-input>
		    </el-form-item>
			<el-form-item label="用户姓名" prop="USERNAME">
			  <el-input v-model="addListForm.USERNAME"></el-input>
			</el-form-item>
			<el-form-item label="用户密码" prop="PASSWORD">
			  <el-input type="pass" v-model="addListForm.PASSWORD"></el-input>
			</el-form-item>
			<el-radio-group v-model="GRADE">
				<el-radio :label="0">代理商</el-radio>
			</el-radio-group>
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="dialogFormVisible = false">取 消</el-button>
		    <el-button type="primary" @click="AddList">确 定</el-button>
		  </div>
		</el-dialog>
		<!-- 左侧抽屉 -->
		<el-drawer
		  title='账户设置'
		  :visible.sync="drawer"
		  :before-close="handleClose"
		  >
		  <div class="drawer">
			  <div>
				  <el-form label-position="left" :model="addListForm" ref="addListForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="用户ID" prop="ID">
					  <el-input v-model="addListForm.ID" disabled></el-input>
					</el-form-item>
				    <el-form-item label="用户账号" prop="USERID">
						<el-input v-model="addListForm.USERID" disabled></el-input>
				    </el-form-item>
				  	<el-form-item label="用户姓名" prop="USERNAME">
				  	  <el-input v-model="addListForm.USERNAME" disabled></el-input>
				  	</el-form-item>
					<el-form-item label="用户密码" prop="PASSWORD">
					  <el-input type="password" v-model="addListForm.PASSWORD" disabled></el-input>
					</el-form-item>
				  	<el-form-item label="创建时间" prop="CDATE">
				  	  <el-input v-model="addListForm.CDATE" disabled></el-input>
				  	</el-form-item>
					<el-form-item label="最后登陆时间" prop="LOGINDATE">
					  <el-input v-model="addListForm.LOGINDATE" disabled></el-input>
					</el-form-item>
				  </el-form>
			  </div>
			  <!-- <div style="margin-top: 30px;">
				  <el-button type="primary" v-if="showtypes == 0" @click="AddList">保 存</el-button>
				  <el-button type="primary" v-if="showtypes == 1" @click="editList">修 改</el-button>
				  <el-button type="danger" v-if="showtypes == 1" @click="delList">删 除</el-button>
			  </div> -->
		  </div>
		</el-drawer>
	</div>
	
</template>

<script>
	export default {
		data(){
			return{
				GRADE:0,
				drawer: false,			//抽屉
				dialogFormVisible :false,
				total:0,
				ArrList: [],
				queryInfo:{
					query:'',
					pagenum:1,
					pagesize:10
				},
				// 添加用户参数
				addListForm:{
					USERID:'',
					USERNAME:'',
					PASSWORD:'',
					CDATE:'',
					LOGINDATE:'',
					ISCHECKED:false
				},
				// 添加用户对话框验证规则
				rules:{
					USERID:[
						{ required: true, message: '请输入用户账号', trigger: 'blur' },
						{ min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
					],
					USERNAME:[
						{ required: true, message: '请输入用户姓名', trigger: 'blur' },
						{ min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
					],
					PASSWORD:[
						{ required: true, message: '请输入用户密码', trigger: 'blur' },
						{ min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
					]
							
				},
			}
		},
		created() {
			this.ListArr()
		},
		methods:{
			checkchange(){
				console.log(this.addListForm.ISCHECKED)
			},
			//新增用户
			AddUser(){
				this.addListForm ={}
				this.dialogFormVisible = true
			},
			//打开抽屉
			opendrawer(){
				this.drawer = true
			},
			//清空input
			seachVal(){
				this.ListArr()
			},
			handleEdit(index, row) {
				// console.log(index, row);
				this.addListForm.ID = row[0]
				this.addListForm.USERID = row[1]
				this.addListForm.USERNAME = row[2]
				this.addListForm.PASSWORD = row[3]
				this.addListForm.CDATE = row[4]
				this.addListForm.LOGINDATE = row[5]
				this.drawer = true
			},
			handleClose(done){
				this.drawer =false
			},
			// 每页数据条数发生改变触发
			SizeChange(newsize){
				this.queryInfo.pagesize = newsize;
				this.ListArr();
			},
			// 当前页码发生改变触发
			CurrentChange(newnum){
				this.queryInfo.pagenum = newnum;
				this.ListArr();
			},
			//获取列表
			ListArr(){
				this.ArrList = []
				let parm = new URLSearchParams()
				let getUserSession = JSON.parse(sessionStorage.getItem('userSession'));
				parm.append('query',this.queryInfo.query);
				parm.append('TOKEN',getUserSession.token);
				// parm.append('pagesize',this.queryInfo.pagesize);
				this.$axios.post('/adminlist.php',parm)
				.then(res=>{
					// console.log(res)
					if (res.data.code == 200){
						this.ArrList = res.data.data
						// console.log(this.ddList)
						// this.total = res.data.total
					}
				})
			},
			// 添加用户事件
			AddList(){
				// console.log(this.randomString(8));return
				let getUserSession = JSON.parse(sessionStorage.getItem('userSession'));
				// console.log(getUserSession.token)
				let parm = new URLSearchParams()
				parm.append('ID',this.randomString(8));
				parm.append('TOKEN',getUserSession.token);
				parm.append('USERID',this.addListForm.USERID);
				parm.append('USERNAME',this.addListForm.USERNAME);
				parm.append('PASSWORD',this.addListForm.PASSWORD);
				parm.append('GRADE',this.GRADE);
				this.$refs.addListForm.validate((valid) => {
					if (!valid) return;
					this.$axios.post('addadmin.php',parm).then(res=>{
						console.log(res)
						if(res.data.code == 200) {
							this.$message.success(res.data.msg)
							this.ListArr()
							this.drawer = false
						} else {
							this.$message.error(res.data.msg)
						}
						
					})
				})
			},
			//随机代理商号
			randomString(length) {
			  let result           = '';
			  let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
			  let charactersLength = characters.length;
			  for (let i = 0; i < length; i++) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
			  }
			  return result;
			}
		},
	}
</script>

<style scoped>
	.bigDiv{font-family: 微软雅黑,"microsoft yahei";}
	.drawer{padding: 20px;}
	.d_header{display: flex;align-items: center;}
	.d_header h1{font-weight: 100;font-size: 25px;font-family: 微软雅黑;float: left;padding: 0;margin: 0;}
	.d_header span {color: #ccc;}
</style>